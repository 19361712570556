<!--拼团列表-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-wrap">
                <label>场景：</label>
                <el-select v-model="select.status" placeholder="选择预售状态" filterable clearable class="selectWidth" @change="curr=1;getList()">
                    <el-option v-for="item in selectData" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
                <el-date-picker v-model="select.time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="预售时间" end-placeholder="筛选" value-format="yyyy-MM-dd" @change="curr=1;getList()">
                </el-date-picker>
                <el-input placeholder="搜索商品名称" v-model="select.name" size="small" style="width:300px;" @change="curr=1;getList()" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff" @click="curr=1;getList()"></el-button>
                </el-input>
                <!-- <el-date-picker v-model="select.book_time_end" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="结束时间" end-placeholder="筛选" value-format="yyyy-MM-dd" @change="curr=1;getList()">
                </el-date-picker> -->
            </div>
        </div>
        
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="product.id" label="商品ID" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="product.name" label="商品名称" show-overflow-tooltip></el-table-column>
				<el-table-column label="商品图片" width="120" show-overflow-tooltip>
				     <template slot-scope="scope">
				         <el-image class="tableImage" :src="domain + scope.row.product.image" fit="cover" :preview-src-list="previewList"></el-image>
				     </template>
				 </el-table-column>
               <!-- 
                <el-table-column prop="group_price" label="预售价格" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sales" label="销量" width="150" show-overflow-tooltip></el-table-column>
                 -->
				 <el-table-column label="预售状态" width="150" show-overflow-tooltip>
				     <template slot-scope="scope">
				         <span v-if="scope.row.ext_status == 0" style="color:#67C23A">未开始</span>
				         <span v-if="scope.row.ext_status == 1" style="color:#7B84FF">进行中</span>
				         <span v-if="scope.row.ext_status == 2" style="color:#F56C6C">已结束</span>
				     </template>
				 </el-table-column>
                <el-table-column prop="ext_pay_first_from_time" label="开始时间" width="250" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ext_pay_first_to_time" label="结束时间" width="250" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="300">
                    <!-- <template slot="header">
                        <el-input placeholder="搜索商品名称" v-model="select.search" size="small" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="curr=1;getList()"></el-button>
                        </el-input>
                    </template> -->
                    <template slot-scope="scope">
                        <el-button type="success" plain size="mini" @click="showDetail(scope.row)">查看</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
		
		
	</div>
</template>
<script>
    import {shopGroupListApi,shopGroupDeleteApi,ysalesListApi,ysalesDelApi}  from '@/api/store.js';
    export default {
        data() {
            return {
                select:{},
                selectData:[{
                    id:1,
                    name:'进行中'
                },{
                    id:0,
                    name:'未开始'
                },{
                    id:2,
                    name:'已结束'
                }],//下拉选择列表
                list:[], //商品列表
                previewList:[], //图片预览列表

                title:'添加商品',
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
				
				
				 dialogFormVisible:false,//活动设置弹窗
				  formData:{},
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //预售列表
            getList: function(){
                ysalesListApi({
                    row:this.row,
                    curr:this.curr,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                    response.list.forEach(item => {
                        this.previewList.push(this.domain + item.product.image);
                    });
                })
            },
            //进入商品详情
            showDetail: function(param = {}){
                this.title = '商品详情';
                console.log(param)
                //跳转至添加or编辑页面
                this.$router.push({path:'/shopAdd',query:{id:param.product_id,title:this.title,isShow:JSON.stringify(true)}});
            },
            //删除商品
            deletes:function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    ysalesDelApi({
                        id:id
                    }).then(()=>{
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                    })
                }).catch(()=>{

                })
            },
            //设置上架
            setupSwitch: function(row) {
                console.log(row)
            },
            //批量设置上架
            handleSelectionChange: function(val) {
                console.log(val)
            },

            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
			
			/**
			 * 新增预售功能
			*/
			/**
			 * 活动设置
			*/
			setupActive: function(row){
			    this.dialogFormVisible = true;
			    this.formData = row;
			    this.$set(this.formData,'seckill',false);
			    this.$set(this.formData,'group',false);
			    this.$set(this.formData,'is_best',false)
			},
			
		
		
		},
    }
</script>